import { Footer } from "antd/lib/layout/layout";
import React from "react";
import styles from "./CsaFooter.module.scss";
import fb from "../../images/facebook.png";
import insta from "../../images/instagram.png";
import Logo from "../../images/logo.png";
import mfb from "../../images/mobFB.png";
import minsta from "../../images/mobInsta.png";
import mtwitter from "../../images/mobTweet.png";
import twitter from "../../images/twitter.png";
import { Col, Divider, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";

function CSAFooter() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear().toString();

  return (
    <Footer className={styles.CsaFooterWrapper}>
      <Row className="w-100 d-none d-lg-flex d-xl-flex " align="bottom">
        &nbsp;
        <Col span={6} className={styles.yellow}>
          <img src={Logo} alt="logo-footer" onClick={() => navigate("/home")} />
        </Col>
        <Col span={18} className={styles.green}>
          <Row align="middle">
            <Col  span={12}>
              <p className={styles.copyRight}>
        All rights reserved © Cricket South Africa {currentYear}.
              </p>
         <a className={styles.tnc} href="https://cricket.co.za/terms-and-conditions/" >Terms and Conditions</a> 
         <Divider type="vertical" style={{backgroundColor:"white"}} />
         <a className={styles.tnc} href="https://cricket.co.za/privacy-policy/" >Privacy Policy</a>
            </Col>
            <Col span={12}>
              <Row align="end" justify="center" className="px-3">
                <Col className="px-3">
                  <p className={styles.copyRight}>Get in touch!</p>
                </Col>
                <Col className="mx-1">
                  <img src={fb} alt="fb" />{" "}
                </Col>
                <Col className="mx-1">
                  <img src={insta} alt="insta" />
                </Col>
                <Col className="mx-1">
                  <img src={twitter} alt="tweet" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        xs={24}
        sm={24}
        md={24}
        lg={0}
        xl={0}
        className="w-100 d-flex d-lg-none d-xl-none"
        align="middle"
        justify=""
      >
        <div className={styles.mobFooter}>
          <img src={Logo} alt="logo-footer" onClick={() => navigate("/home")} />
          <p className={styles.copyRight}>
           All rights reserved © Cricket South Africa {currentYear}.
          </p>
          <a className={styles.tnc2} href="https://cricket.co.za/terms-and-conditions/" >Terms and Conditions</a> 
         <a className={styles.tnc2} href="https://cricket.co.za/privacy-policy/" >Privacy Policy</a>
          <p className={styles.copyRight}>Get in touch!</p>
          <Row align="middle" justify="center" className="px-2 mt-1">
            <Col className="mx-1">
              <img src={mfb} alt="fb" />{" "}
            </Col>
            <Col className="mx-1">
              <img src={minsta} alt="insta" />
            </Col>
            <Col className="mx-1">
              <img src={mtwitter} alt="tweet" />
            </Col>
          </Row>
        </div>
      </Row>
    </Footer>
  );
}

export default CSAFooter;
