/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Row, Col, Pagination } from "antd";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import * as masterData from "../../utils/commonMasterData";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData, setCurrentTabState } from "redux/slice/commonSlice";

function CMSVenues({
  styles,
  setStep,
  setInfo,
  info,
  setActiveKey,

  status,
}) {
  const { addToast } = useToasts();
  const CMSData = useSelector((state) => state.auth.cms_data);
  const [tableFieldData, setTableFieldData] = useState(
    CMSData?.tab2Step3?.tableFieldData || CMSData?.tableFieldData || []
  );
  const [isPagination, setIsPagination] = useState(false);
  const [resData, setResData] = useState([]);
  const [subMemberValue, setSubmemberValue] = useState("");
  const [sMemberId, setSMemberID] = useState("");

  const steps = ["Hosting & Participating Members", "Teams", "Venues"];
  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Sub Member",
      dataIndex: "subMember",
      key: "subMember",
    },
    {
      title: "Field Name",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Action",
      key: "action",
      render: (item) =>
        tableFieldData.some((i) => i.key === item?.key) ? (
          <CheckOutlined style={{ cursor: "pointer", color: "#007237" }} />
        ) : (
          <PlusOutlined
            style={{ cursor: "pointer", color: "#007237" }}
            onClick={() => handleAdd(item)}
          />
        ),
      //align: "center",
    },
  ];
  const columns1 = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Sub Member",
      dataIndex: "subMember",
      key: "subMember",
    },
    {
      title: "Field Name",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <DeleteOutlined
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleRemove(item)}
        />
      ),
      // align: "center",
    },
  ];

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getRegisteredMemberList,
    method: "get",
  });
  const {
    callAPI: SearchAPI,
    response: searchResponse,
    error: searchErr,
    loading: searchLoad,
  } = useAxios({
    url: serviceUrl.getCMSFieldList,
    method: "get",
    query: true,
  });
  const {
    callAPI: FetchAPI,
    response: fetchResponse,
    error: fetchErr,
    loading: fetchLoad,
  } = useAxios({
    url: serviceUrl.getCMSSubMemberList,
    method: "get",
    query: true,
  });
  useEffect(() => {
    callAPI();
  }, []);

  const getMemberNameById = (arr, id) => {
    let objectArray = [];
    let memberItem;
    if (arr?.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let memberNamePar = response?.find((item) => item?.id == arr[i])?.name;
        if (memberNamePar) {
          objectArray.push({
            value: arr[i],
            label: memberNamePar,
          });
        }
      }
    } else {
      memberItem = response?.find((item) => item?.id == id)?.name;
    }
    return id ? memberItem : objectArray;
  };
  const getSubMemberNameById = (id) => {
    let subMemberItem;
    if (id) {
      subMemberItem =
        fetchResponse && values?.subMember === "clubs_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.club_school_name
          : values?.subMember === "high_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.high_school_name
          : values?.subMember === "primary_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.primary_school_name
          : fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.ancillary_member_name;
    }
    return subMemberItem;
  };


const handleSearch = () => {
  const { membersName, subMember, subMemberName } = values;

  if (!membersName && !subMember && !subMemberName) {
    addToast("Please select any of one search criteria", {
      appearance: "warning",
    });
    return;
  }

  if (!subMember && !subMemberName && membersName) {
    SearchAPI(`?member_id=${membersName}`, true);
    return;
  }

  if (subMember && !subMemberName) {
    addToast("Please select sub member name", {
      appearance: "warning",
    });
    return;
  }

  SearchAPI(`?uid=${subMemberName}&member_id=${membersName}`, true);
};

const onChange = (page) => {
  const { subMember, subMemberName, membersName } = values;

  if (!subMember) {
    SearchAPI(`?member_id=${membersName}&page=${page}`, true);
  } else if (subMember && !subMemberName && !membersName) {
    SearchAPI(`?type=${subMember}&member_id=All&page=${page - 1}`, true);
  } else {
    SearchAPI(
      `?uid=${subMemberName}&member_id=${membersName}&page=${page - 1}`,
      true
    );
  }
};
  const data = CMSData?.tab2Step3 || {};
  const dispatch = useDispatch();
  const cms_data = useSelector((state) => state.auth.cms_data);

  const formik = useFormik({
    initialValues: {
      membersName: data ? data?.membersName : "",
      subMember: data ? data?.subMember : "",
      subMemberName: data ? data?.subMemberName : "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setInfo({
        step1: info?.step1,
        step2: info?.step2,
        tableData: info?.tableData,
        step3: values,
        tableFieldData: tableFieldData,
      });

      let playersArr = [];
      info?.step1?.membersNameP?.find((p) => {
        let player = response?.find((item) => p === item?.id);
        if (player) {
          playersArr?.push(player);
        }
      });

      await dispatch(
        setCMSData({
          ...CMSData,
          tab1Step1: CMSData?.tab1Step1,
          tab1Step2: CMSData?.tab1Step2,
          tab1: CMSData?.tab1,
          tab2Step1: CMSData?.tab2Step1,
          tab2Step2: CMSData?.tab2Step2,
          tab2Step3: values,
          tab2: {
            competition_settings: [
              {
                key: "team_list_CMS",
                values: info?.tableData?.map((item) => item.key),
              },
              {
                key: "venue_list",
                values: tableFieldData?.map((item) => item.key),
              },
              {
                key: "cms_participating_member_List",
                values: playersArr?.map((item) => item.id),
              },
              {
                key: "cms_host_member_List",
                values: playersArr?.map((item) => item.id),
              },
              {
                key: "cms_host_member_list",
                values: info?.step1?.membersNameH?.map((item) => item.id),
              },
            ],
            step1: info?.step1,

            participatingMemberList: playersArr?.map((item) => item.id),
            MemberName: playersArr,
            step2: info?.step2,
            tableData: info?.tableData?.map((item) => item?.key),
            teamData: info?.tableData,
            step3: values,
            tableFieldData: tableFieldData?.map((item) => item?.key),
            FieldData: tableFieldData,
          },
          tab3Step1: CMSData?.tab3Step1,
          tab3Step2: CMSData?.tab3Step2,
          tab3: CMSData?.tab3,
          tableFieldData,
        })
      );

      setActiveKey("4");
      dispatch(setCurrentTabState(4));
      setStep(1);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  useEffect(() => {
    (values?.subMember &&
      !values?.membersName &&
      FetchAPI(`?type=${values?.subMember}`, true)) ||
      (values?.subMember &&
        values?.membersName &&
        FetchAPI(`?type=${values?.subMember}&member_id=${sMemberId}`, true));
    //setFieldValue("subMemberName", "");
  }, [values?.subMember, values.membersName]);

  useEffect(() => {
    setResData([]);
  }, [values.subMember, values?.membersName]);

  useEffect(() => {
    if (fetchResponse) {
      isPagination
        ? setResData([...resData, ...fetchResponse?.rows])
        : setResData(fetchResponse?.rows);
    }
  }, [fetchResponse]);

  const handleSubmemberSearch = (val) => {
    setIsPagination(false);
    setSubmemberValue(val);
    FetchAPI(
      `?type=${values?.subMember ? values?.subMember : ""}&member_id=${
        sMemberId ? sMemberId : ""
      }&keyword=${val}`,
      true
    );
  };

  const handleScroll = (event) => {
    setIsPagination(true);
    const target = event.target;

    const totalHeight = target.scrollHeight - 1;
    const scrollHeight = target.scrollTop + target.offsetHeight;
    const totalPage = fetchResponse?.pager?.total_pages;
    const curPage = fetchResponse?.pager.current_page + 1;

    if (
      (totalPage != curPage &&
        parseInt(scrollHeight.toFixed(0)) === totalHeight) ||
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight
    ) {
      FetchAPI(
        `?type=${values?.subMember ? values?.subMember : ""}&member_id=${
          sMemberId ? sMemberId : ""
        }&keyword=${subMemberValue ? subMemberValue : ""}&page=${
          fetchResponse?.pager.current_page + 1
        }`,
        true
      );
    }
  };

  const dataTable = searchResponse?.rows?.map((item) => ({
    key: item?.team_id,
    member: getMemberNameById([], item?.member_id),
    subMember: !values?.subMemberName ? "N/A" : getSubMemberNameById(item?.uid),
    // subMember: getSubMemberNameById(item?.uid) || "N/A",
    field: item?.team_name,
    ...item,
  }));

  const handleAdd = (arrField) => {
    const obj = {};
    const isThere = tableFieldData?.find(
      (item) => item?.fieldId === arrField?.team_id
    );
    if (!isThere) {
      obj.key = arrField?.team_id;
      obj.fieldId = arrField?.team_id;
      obj.member = getMemberNameById([], arrField?.member_id);
      obj.subMember = !values?.subMemberName
        ? "N/A"
        : getSubMemberNameById(arrField?.uid);
      obj.field = arrField?.team_name;
      setTableFieldData((tableFieldData) => [...tableFieldData, obj]);
    } else {
      addToast("Same Venue added Already", {
        appearance: "warning",
      });
    }
  };
  const handleRemove = (fieldArr) => {
    const arr = tableFieldData?.filter(
      (item) => item?.fieldId !== fieldArr?.fieldId
    );
    setTableFieldData(arr);
  };

  return (
    <div className="mt-3 pt-3 px-3">
      <CsaStepper steps={steps} activeKey={2} />
      {/* {fetching ? (
    <Spin />
   ) : ( */}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Member"
            placeholder="Select Member"
            name="membersName"
            value={values.membersName}
            optionType
            menuArr={getMemberNameById(info?.step1?.membersNameP)}
            onChange={(e) => {
              setFieldValue("membersName", e);
              setSMemberID(e);
            }}
            onBlur={() => setFieldTouched("membersName", true)}
            touched={touched.membersName}
            error={errors.membersName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Sub-Member Type"
            placeholder="Select Sub-Member Type"
            name="subMember"
            value={values.subMember}
            optionType
            onClear={() => formik.setFieldValue("subMemberName", "")}
            menuArr={masterData.submemberList}
            onChange={(e) => {
              setFieldValue("subMember", e);
            }}
            defaultValue={values.subMember}
            onBlur={handleBlur}
            touched={touched.subMember}
            error={errors.subMember || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Sub-Member Name"
            placeholder="Select Sub-Member Name"
            name="subMemberName"
            onChange={(e) => {
              setFieldValue("subMemberName", e);
            }}
            onSearch={handleSubmemberSearch}
            onScroll={handleScroll}
            loading={fetchLoad}
            value={values.subMemberName}
            optionType
            menuArr={
              resData && resData?.length > 0
                ? resData?.map((p) => ({
                    key: p?.uid,
                    value: p?.uid?.toString(),
                    label:
                      values?.subMember === "clubs_schools"
                        ? p?.club_school_name
                        : values?.subMember === "high_schools"
                        ? p?.high_school_name
                        : values?.subMember === "primary_schools"
                        ? p?.primary_school_name
                        : p?.ancillary_member_name,
                    ...p,
                  }))
                : []
            }
            onBlur={handleBlur}
            touched={touched.subMemberName}
            error={errors.subMemberName || null}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-end"
        >
          <CsaButton
            label="Search"
            textColor="#FFF"
            onClick={() => handleSearch()}
            btnColor="#007237"
          />
        </Col>
      </Row>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Search Results:</p>
        <Table
          className="center-aligned"
          loading={searchLoad}
          columns={columns}
          dataSource={dataTable}
          pagination={false}
          scroll={{
            x: 380,
          }}
          rowClassName={(rec) =>
            tableFieldData.some((item) => item.key === rec?.key) &&
            "disabled-row"
          }
        />
        <div className=" d-flex w-100 justify-content-end mt-5">
          <Pagination
            onChange={onChange}
            total={searchResponse?.pager?.total_items}
            size={"small"}
          />
        </div>
      </div>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Selected Venues:</p>
        <Table
          className="center-aligned"
          //   loading={loading}
          columns={columns1}
          dataSource={tableFieldData}
          pagination={false}
          scroll={{
            x: 470,
          }}
        />
        {/* <div className=" d-flex w-100 justify-content-end mt-5">
     <Pagination
       onChange={onChange}
       //    total={response?.pager?.total_items}
       size={"small"}
      />
    </div> */}
      </div>
      <div className="mt-4  d-flex w-100 justify-content-end">
        <CsaButton
          label="Back"
          textColor="#007237"
          onClick={() => {
            setInfo({
              step1: info?.step1,
              step2: info?.step2,
              tableData: info?.tableData,
              step3: values,
              tableFieldData: tableFieldData,
            });
            setStep(2);
          }}
          btnColor="#FFCB03"
        />
        <CsaButton
          label="Save"
          textColor="#FFF"
          onClick={() => handleSubmit()}
          btnColor="#007237"
          disabled={tableFieldData?.length === 0}
          //  loading={loading}
        />
      </div>

      {/* )} */}
    </div>
  );
}

export default CMSVenues;
