import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Navs,
  Navigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "pages/Dashboard";
import Home from "pages/Home";
import Login from "pages/Login";
import StaffMembers from "pages/StaffMembers";
import StaffMemberTable from "pages/StaffMembers/StaffMemberTable";
import Coaches from "pages/Coaches";
import CoachesTable from "pages/Coaches/CoachesTable";
import Umpires from "pages/Umpires";
import UmpiresTable from "pages/Umpires/UmpiresTable";
import Scorers from "pages/Scorers";
import ScorersTable from "pages/Scorers/ScorersTable";
import MatchReferees from "pages/MatchReferees";
import MatchRefereesTable from "pages/MatchReferees/MatchRefereesTable";
import Players from "pages/Players";
import PlayersTable from "pages/Players/PlayersTable";
import Teams from "pages/Teams";
import TeamsTable from "pages/Teams/TeamsTable";
import Members from "pages/Members";
import MembersTable from "pages/Members/MembersTable";
import PrimarySchools from "pages/PrimarySchools";
import PrimarySchoolsTable from "pages/PrimarySchools/PrimarySchoolsTable";
import HighSchools from "pages/HighSchools";
import HighSchoolsTable from "pages/HighSchools/HighSchoolsTable";
import ClubSchools from "pages/ClubSchools";
import ClubSchoolsTable from "pages/ClubSchools/ClubSchoolsTable";
import AncillaryMember from "pages/AncillaryMember";
import AncillaryMemberTable from "pages/AncillaryMember/AncillaryMemberTable";
import Fields from "pages/Fields";
import FieldsTable from "pages/Fields/FieldsTable";
import Nets from "pages/Nets";
import NetsTable from "pages/Nets/NetsTable";
import Recovery from "pages/Recovery";
import ChangePassword from "pages/ChangePassword";
import MyProfile from "pages/MyProfile";
import NotFound from "pages/NotFound";
import LoginAs from "pages/LoginAs";
import RecoveryPwd from "pages/RecoveryPwd";
import CompetitionManagementTable from "pages/CompetitionManagement/CompetitionManagementTable";
import CompetitionManagement from "pages/CompetitionManagement";
import HubRpc from "pages/HubRpc/HubRpc";
import HubRpcTable from "pages/HubRpc/HubRpcTable";
import Schedule from "pages/ScheduleTournaments/Schedule";
import Tournaments from "pages/Tournaments";
import MiniCricket from "pages/MiniCricket";
import MiniCricketTable from "pages/MiniCricket/MiniCricketTable";
import Reports from "pages/Reports/Resports";
import { Children } from "react";
import CsaCards from "Common/CsaCards";
import Progress from "pages/ProgressTournaments/Progress";
import Statistics from "pages/Statistics";
import PlayerStatsTable from "pages/Statistics/PlayerStatsTable";
import TeamStatsTable from "pages/Statistics/TeamStatsTable";
import PlayerInformation from "pages/PlayerInformation/PlayerInformation";
import TeamInformation from "pages/TeamInformation/TeamInformation";
import InProgressTournaments from "pages/ProgressTournaments/InProgressTournaments.";
import FutureTournaments from "pages/ProgressTournaments/FutureTournaments";
import CompletedTournaments from "pages/ProgressTournaments/CompletedTournaments";
import { useDispatch } from "react-redux";
import { isLogin, setCSALevel, setCSARole } from "redux/slice/commonSlice";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import ClubAdministration from "pages/ClubAdministration/ClubAdministration";
import Graphs from "pages/Graphs/Graphs";
import StreamingOverlayScreen from "pages/StreamingOverlay";
import { checkTokenValidity, getPayload } from "utils/helpers";
import { useToasts } from "react-toast-notifications";

function Routes() {
  const dispatch = useDispatch();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.logout + `${localStorage.getItem("csa_logout_token")}`,
    method: "post",
  });



  const PrivateRoute = ({ children }) => {
    const userLevels = localStorage?.getItem("user_level");
const allowLoginAs = userLevels?.includes("CSA")
console.log(userLevels,allowLoginAs,"rolees")

    const auth = localStorage.getItem("csa_token");
  
    const tokenIsValid = checkTokenValidity(auth);
    const { addToast } = useToasts();
    if (!tokenIsValid) {
      const handleLogout = async () => {
        dispatch(isLogin(false));
        dispatch(setCSALevel(null));
        dispatch(setCSARole(null));
        await callAPI();
        await localStorage.clear();
        //  navigate("/");
        addToast("Session Expired, Please Login Again", {
          appearance: "warning",
        });
      };
      handleLogout();
    }

    return auth && tokenIsValid ? children : <Navigate to="/login" />;
  };

  console.log("FIX 3");

  return (
    <Router>
      <Navs>
        <Route path="" element={<Navigate to="/score" replace />} />
        <Route path="/" element={<Dashboard />}>
          <Route path="score" element={<CsaCards />} />
          <Route path="score/:id" element={<Tournaments />} />
          <Route path="tournaments" element={<Progress />}>
            {/* <Route path="tournaments" element={<Schedule />}> */}
            <Route path="in-progress" element={<InProgressTournaments />} />
            <Route path="future" element={<FutureTournaments />} />
            <Route path="completed" element={<CompletedTournaments />} />
          </Route>
          <Route path="tournaments/in-progress/:id" element={<Schedule />} />
          <Route path="tournaments/future/:id" element={<Schedule />} />
          <Route path="tournaments/completed/:id" element={<Schedule />} />

          <Route
            path="tournaments/in-progress/:id/:id"
            element={<Tournaments />}
          />
          <Route
            path="tournaments/completed/:id/:id"
            element={<Tournaments />}
          />

          <Route path="stats" element={<Statistics />}>
            <Route path="players" element={<PlayerStatsTable />} />
            <Route path="teams" element={<TeamStatsTable />} />
          </Route>
          <Route path="stats/players/:id" element={<PlayerInformation />} />
          <Route path="stats/teams/:id" element={<TeamInformation />} />
        </Route>
        <Route path="home" element={<Navigate to="/home/score" replace />} />
        <Route
          path="home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        >
          <Route path="score" element={<CsaCards />} />
          <Route path="score/:id" element={<Tournaments />} />
          <Route path="tournaments" element={<Progress />}>
            {/* <Route path="tournaments" element={<Schedule />}> */}
            <Route path="in-progress" element={<InProgressTournaments />} />
            <Route path="future" element={<FutureTournaments />} />
            <Route path="completed" element={<CompletedTournaments />} />
          </Route>
          <Route path="tournaments/in-progress/:id" element={<Schedule />} />
          <Route path="tournaments/future/:id" element={<Schedule />} />
          <Route path="tournaments/completed/:id" element={<Schedule />} />

          <Route
            path="tournaments/in-progress/:id/:id"
            element={<Tournaments />}
          />
          <Route
            path="tournaments/completed/:id/:id"
            element={<Tournaments />}
          />

          <Route path="stats" element={<Statistics />}>
            <Route path="players" element={<PlayerStatsTable />} />
            <Route path="teams" element={<TeamStatsTable />} />
          </Route>
          <Route path="stats/players/:id" element={<PlayerInformation />} />
          <Route path="stats/teams/:id" element={<TeamInformation />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route
          path="/register-staff"
          element={
            <PrivateRoute>
              <StaffMembers />
            </PrivateRoute>
          }
        />
        <Route
          path="/match/streaming/:id"
          element={<StreamingOverlayScreen />}
        />
        <Route
          path="/view-staff"
          element={
            <PrivateRoute>
              <StaffMembers />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-staff"
          element={
            <PrivateRoute>
              <StaffMembers />
            </PrivateRoute>
          }
        />
        <Route
          path="/staff-list"
          element={
            <PrivateRoute>
              <StaffMemberTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-coach"
          element={
            <PrivateRoute>
              <Coaches />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-coach"
          element={
            <PrivateRoute>
              <Coaches />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-coach"
          element={
            <PrivateRoute>
              <Coaches />
            </PrivateRoute>
          }
        />
        <Route
          path="/coach-list"
          element={
            <PrivateRoute>
              <CoachesTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-umpire"
          element={
            <PrivateRoute>
              <Umpires />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-umpire"
          element={
            <PrivateRoute>
              <Umpires />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-umpire"
          element={
            <PrivateRoute>
              <Umpires />
            </PrivateRoute>
          }
        />
        <Route
          path="/umpire-list"
          element={
            <PrivateRoute>
              <UmpiresTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-scorer"
          element={
            <PrivateRoute>
              <Scorers />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-scorer"
          element={
            <PrivateRoute>
              <Scorers />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-scorer"
          element={
            <PrivateRoute>
              <Scorers />
            </PrivateRoute>
          }
        />
        <Route
          path="/scorer-list"
          element={
            <PrivateRoute>
              <ScorersTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-match-referee"
          element={
            <PrivateRoute>
              <MatchReferees />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-match-referee"
          element={
            <PrivateRoute>
              <MatchReferees />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-match-referee"
          element={
            <PrivateRoute>
              <MatchReferees />
            </PrivateRoute>
          }
        />
        <Route
          path="/match-referee-list"
          element={
            <PrivateRoute>
              <MatchRefereesTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-player"
          element={
            <PrivateRoute>
              <Players />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-player"
          element={
            <PrivateRoute>
              <Players />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-player"
          element={
            <PrivateRoute>
              <Players />
            </PrivateRoute>
          }
        />
        <Route
          path="/player-list"
          element={
            <PrivateRoute>
              <PlayersTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-team"
          element={
            <PrivateRoute>
              <Teams />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-team"
          element={
            <PrivateRoute>
              <Teams />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-team"
          element={
            <PrivateRoute>
              <Teams />
            </PrivateRoute>
          }
        />
        <Route
          path="/team-list"
          element={
            <PrivateRoute>
              <TeamsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-member"
          element={
            <PrivateRoute>
              <Members />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-member"
          element={
            <PrivateRoute>
              <Members />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-member"
          element={
            <PrivateRoute>
              <Members />
            </PrivateRoute>
          }
        />
        <Route
          path="/member-list"
          element={
            <PrivateRoute>
              <MembersTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-primary-school"
          element={
            <PrivateRoute>
              <PrimarySchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-primary-school"
          element={
            <PrivateRoute>
              <PrimarySchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-primary-school"
          element={
            <PrivateRoute>
              <PrimarySchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/primary-school-list"
          element={
            <PrivateRoute>
              <PrimarySchoolsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-high-school"
          element={
            <PrivateRoute>
              <HighSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-high-school"
          element={
            <PrivateRoute>
              <HighSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-high-school"
          element={
            <PrivateRoute>
              <HighSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/high-school-list"
          element={
            <PrivateRoute>
              <HighSchoolsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-club"
          element={
            <PrivateRoute>
              <ClubSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-club"
          element={
            <PrivateRoute>
              <ClubSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-club"
          element={
            <PrivateRoute>
              <ClubSchools />
            </PrivateRoute>
          }
        />
        <Route
          path="/club-list"
          element={
            <PrivateRoute>
              <ClubSchoolsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/mini-cricket"
          element={
            <PrivateRoute>
              <MiniCricketTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/club-administration"
          element={
            <PrivateRoute>
              <ClubAdministration />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-mini-cricket"
          element={
            <PrivateRoute>
              <MiniCricket />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-mini-cricket"
          element={
            <PrivateRoute>
              <MiniCricket />
            </PrivateRoute>
          }
        />
        <Route
          path="/hub-rpc"
          element={
            <PrivateRoute>
              <HubRpcTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-hub-rpc"
          element={
            <PrivateRoute>
              <HubRpc />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-hub-rpc"
          element={
            <PrivateRoute>
              <HubRpc />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-hub-rpc"
          element={
            <PrivateRoute>
              <HubRpc />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-ancillary-member"
          element={
            <PrivateRoute>
              <AncillaryMember />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-ancillary-member"
          element={
            <PrivateRoute>
              <AncillaryMember />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-ancillary-member"
          element={
            <PrivateRoute>
              <AncillaryMember />
            </PrivateRoute>
          }
        />
        <Route
          path="/ancillary-member-list"
          element={
            <PrivateRoute>
              <AncillaryMemberTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/field-list"
          element={
            <PrivateRoute>
              <FieldsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-field"
          element={
            <PrivateRoute>
              <Fields />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-field"
          element={
            <PrivateRoute>
              <Fields />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-field"
          element={
            <PrivateRoute>
              <Fields />
            </PrivateRoute>
          }
        />
        <Route
          path="/net-list"
          element={
            <PrivateRoute>
              <NetsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-net"
          element={
            <PrivateRoute>
              <Nets />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-net"
          element={
            <PrivateRoute>
              <Nets />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-net"
          element={
            <PrivateRoute>
              <Nets />
            </PrivateRoute>
          }
        />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/recover-password" element={<RecoveryPwd />} />
        <Route
          path="/update-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-profile"
          element={
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-user-profile"
          element={
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/login-as"
          element={
<PrivateRoute>
      <LoginAs />
</PrivateRoute>
          }
        />
        <Route
          path="/cms-list"
          element={
            <PrivateRoute>
              <CompetitionManagementTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/cms-view"
          element={
            <PrivateRoute>
              <CompetitionManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/cms-edit"
          element={
            <PrivateRoute>
              <CompetitionManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/cms-create"
          element={
            <PrivateRoute>
              <CompetitionManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path="/graphs"
          element={
            <PrivateRoute>
              <Graphs />
            </PrivateRoute>
          }
        />
        <Route
          path="/match-schedule"
          element={
            <PrivateRoute>
              <Schedule />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Navs>
    </Router>
  );
}

export default Routes;
