import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, AutoComplete } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import confirm from "antd/lib/modal/confirm";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaDatePicker from "Common/CsaDatePicker";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const HighSchoolSchema = Yup.object().shape({
  nationality: Yup.string().required("Select nationality"),
  passportNumber: Yup.string().required("Enter Passport Number"),
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  emailAddress: Yup.string()
    .email("Invalid Email Address")
    .required("Enter Email Address"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Please enter a valid 10 digit mobile number")
    .max(10, "Please enter a valid 10 digit mobile number")
    .required("Enter mobile number"),
  dateofBirth: Yup.string().required("Select Date of Birth"),
  race: Yup.string().required("Select Race"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
  status: Yup.string().required("Select Status"),
});
const HighSchoolLocalSchema = Yup.object().shape({
  nationality: Yup.string().required("Select nationality"),
  iDNumber: Yup.string()
    .matches(/^\d+$/, "Only numbers")
    .required("Enter  National Identity Number.")
    .min(13, "Please enter a valid 13 digit National Identity Number.")
    .max(13, "Please enter a valid 13 digit National Identity Number."),
  passportNumber: Yup.string(),
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  emailAddress: Yup.string()
    .email("Invalid Email Address")
    .required("Enter Email Address"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Please enter a valid 10 digit mobile number")
    .max(10, "Please enter a valid 10 digit mobile number")
    .required("Enter mobile number"),
  dateofBirth: Yup.string().required("Select Date of Birth"),
  race: Yup.string().required("Select Race"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
  status: Yup.string().required("Select Status"),
});

function HighSchoolRegisterForm2({
  styles,
  setStep,
  setInfo,
  info,
  isLocal,
  setLocal,
  img,
  setImg,
}) {
  // use useLocation read data form Checkout page
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const location = useLocation();
  const authRole = localStorage.getItem("selRole");
  const member_name = localStorage.getItem("csa_member_name");

  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const steps = ["General Information", "Administrator"];
  const { addToast } = useToasts();
  const { id, isEdit = false } = location?.state || {};
  const [isExistingUser, setExistingUser] = useState(false);
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editHighSchool : serviceUrl.setHighSchool,
    method: isEdit ? "put" : "post",
  });
  const {
    callAPI: getUserDetailById,
    response: userDetail,
    error: userErr,
    loading: userFetching,
  } = useAxios({
    url: serviceUrl.getUserInfoById,
    method: "get",
    query: true,
  });
  const data = isEdit
    ? {
        nationality: info?.editData?.nationality,
        iDNumber:
          info?.editData?.foreign_user === "False"
            ? info?.editData?.id_number
            : "", //need to change based on api
        passportNumber: info?.editData?.passport_number,
        firstName: info?.editData?.first_name?.replace(/&#039;/g, "'"),
        lastName: info?.editData?.last_name?.replace(/&#039;/g, "'"),
        emailAddress: info?.editData?.field_email_address,
        contactNumber: info?.editData?.contact_number,
        dateofBirth: info?.editData?.date_of_birth,
        race: info?.editData?.race,
        gender: info?.editData?.gender,
        differentlyAbled: info?.editData?.differently_abled,
        status: info?.editData?.status === "On" ? "Active" : "Disable" || "",
      }
    : isExistingUser
    ? {
        nationality: userDetail?.[0]?.nationality,
        iDNumber:
          userDetail?.[0]?.foreign_user === "False"
            ? userDetail?.[0]?.id_number
            : "",
        passportNumber: userDetail?.[0]?.passport_number,
        firstName: userDetail?.[0]?.first_name,
        lastName: userDetail?.[0]?.last_name,
        emailAddress: userDetail?.[0]?.field_email_address,
        contactNumber: userDetail?.[0]?.contact_number,
        dateofBirth: userDetail?.[0]?.date_of_birth,
        race: userDetail?.[0]?.race,
        gender: userDetail?.[0]?.gender,
        differentlyAbled: userDetail?.[0]?.differently_abled,
      }
    : info?.step2 || {};
  useEffect(() => {
    setLocal(
      userDetail && userDetail?.[0]?.foreign_user === "True" ? false : true
    );
  }, [userDetail]);
  const onSearch = (val) => {
    if (!val) return;
    let filtered = masterData.nationality.filter(
      (obj) =>
        obj.key !== 0 &&
        obj.value.toString().toLowerCase().includes(val.toLowerCase())
    );
    setOptions(filtered);
  };
  const onSelect = (value) => {};
  const setLevel = (arr) => {
    if (isExistingUser === true) {
      arr.push("SUB-MEMBER");
    }
    return arr;
  };
  const formik = useFormik({
    initialValues: {
      nationality: data ? data?.nationality || "South Africa" : "South Africa",
      iDNumber: data ? data?.iDNumber : "",
      passportNumber: data ? data?.passportNumber : "",
      firstName: data ? data?.firstName : "",
      lastName: data ? data?.lastName : "",
      emailAddress: data ? data?.emailAddress : "",
      contactNumber: data ? data?.contactNumber : "",
      dateofBirth: data ? data?.dateofBirth : "",
      race: data ? data?.race : "",
      gender: data ? data?.gender : "",
      differentlyAbled: data ? data?.differentlyAbled || "None" : "None",
      status: data?.status ||"Active",
    },
    validationSchema: isLocal ? HighSchoolLocalSchema : HighSchoolSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({ step1: info?.step1, step2: values });
      const obj = {
        email_address: values.emailAddress,
        nationality: values.nationality,
        id_number: values.iDNumber?.toString() || "",
        passport_number: values.passportNumber?.toString() || "",
        contact_number: values.contactNumber?.toString() || "",
        first_name: values.firstName,
        last_name: values.lastName,
        dob: values.dateofBirth,
        race: values.race,
        gender: values.gender,
        differently_abled: values.differentlyAbled,
        status: values?.status === "Active" ? true : false || false,
        municipality: info?.step1?.municipality,
        natemis: info?.step1?.natEMIS,
        school_name: info?.step1?.schoolName,
        phase_ped: info?.step1?.phasePed,
        commonly_known_as: info?.step1?.commonlyKnownAs,
        sector: info?.step1?.sector,
        settlement_type: info?.step1?.settlementType,
        school_type: info?.step1?.schoolType,
        web_address: info?.step1?.webAddress,
        address: info?.step1?.address,
        latitude: info?.step1?.latitude,
        longitude: info?.step1?.longitude,
        kfc_mini_cricket:
          info?.step1?.kfcMiniCricket === "Yes" ? true : false || false,
        school_status:
          info?.step1?.schoolStatus === "Active" ? true : false || false,
        user_level:
          isExistingUser === true
            ? setLevel(userDetail?.[0]?.user_level)
            : isEdit
            ? info?.editData?.field_level
            : "SUB-MEMBER",
        roles: ["sub_members_administrator"],
        member_id: authLevel === "csa" ? "" : memberId,
        uid: uid,
        member_name,
      };

      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/high-school-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  useEffect(() => {
    if (values.iDNumber?.length === 13) {
      getUserDetailById(values.iDNumber, true);
    }
  }, [values?.iDNumber]);

  useEffect(() => {
    if (userDetail && userDetail?.length > 0 && !isEdit && !values.firstName) {
      confirm({
        title: "Confirm",
        content:
          "This user already registered for another role.Do you still want to continue with new role?",
        onOk: () => setExistingUser(true),
        onCancel: () => resetForm(),
        okText: "Yes",
        cancelText: "No",
        okButtonProps: { style: { backgroundColor: "#007237" } },
        cancelButtonProps: {
          style: { backgroundColor: "#FFCB03", color: "#007237" },
        },
      });
    }
  }, [userDetail]);
  const checkValidity = () => {
    const {
      nationality,
      iDNumber,
      passportNumber,
      firstName,
      lastName,
      emailAddress,
      contactNumber,
      dateofBirth,
      race,
      gender,
      differentlyAbled,
      status,
    } = values;
    const isVAlid = isLocal
      ? nationality &&
        iDNumber &&
        firstName &&
        lastName &&
        emailAddress &&
        dateofBirth &&
        race &&
        gender &&
        contactNumber &&
        differentlyAbled &&
        status &&
        Object.keys(errors).length === 0
      : nationality &&
        passportNumber &&
        firstName &&
        lastName &&
        contactNumber &&
        emailAddress &&
        dateofBirth &&
        race &&
        gender &&
        differentlyAbled &&
        status &&
        Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };
  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <p className={styles.name}>
            {"Nationality"}
            {<span className={styles.required}>*</span>}
          </p>
          <AutoComplete
            style={{
              width: "100%",
            }}
            allowClear
            options={options}
            name="nationality"
            required
            onChange={(e) => {
              setFieldValue("nationality", e);
              setLocal(e === "South Africa");
            }}
            defaultValue={values.nationality || "South Africa"}
            value={values.nationality}
            onSelect={(val, option) => onSelect(val, option.label)}
            onSearch={onSearch}
            onBlur={(e) => {
              handleBlur(e);
              const input = e.target.value;
              if (input) {
                let filtered = masterData.nationality?.find(
                  (val) => val?.value?.toLowerCase() === input?.toLowerCase()
                );
                if (filtered) {
                  setFieldValue("nationality", filtered?.value);
                } else {
                  setFieldValue("nationality", "");
                }
              }
            }}
            error={errors.nationality || null}
            disabled={
              authRole === "CSA ADMINISTRATOR" ||
              authRole === "CSA ADMIN ASSISTANT"
                ? null
                : isEdit
            }
          ></AutoComplete>
          {errors.nationality && (
            <pre className="text-danger">{errors.nationality}</pre>
          )}
        </Col>

        {isLocal && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="ID Number"
              placeholder="Enter ID Number"
              type="text"
              name="iDNumber"
              maxLength={13}
              onChange={handleChange}
              value={values.iDNumber}
              required={isLocal}
              onBlur={handleBlur}
              touched={touched.iDNumber}
              error={errors.iDNumber || null}
              disabled={
                authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT"
                  ? null
                  : isEdit
              }
              isNumber={true}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Passport Number"
            placeholder="Enter Passport Number"
            type="text"
            name="passportNumber"
            onChange={handleChange}
            value={values.passportNumber}
            required={!isLocal}
            onBlur={handleBlur}
            touched={touched.passportNumber}
            error={errors.passportNumber || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="First Name"
            placeholder="Enter First Name"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            required
            onBlur={handleBlur}
            touched={touched.firstName}
            error={errors.firstName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Last Name"
            placeholder="Enter Last Name"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            required
            onBlur={handleBlur}
            touched={touched.lastName}
            error={errors.lastName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Email Address"
            placeholder="Enter Email Address"
            type="text"
            name="emailAddress"
            onChange={handleChange}
            value={values.emailAddress}
            required
            onBlur={handleBlur}
            touched={touched.emailAddress}
            error={errors.emailAddress || null}
            disabled={
              authRole === "CSA ADMINISTRATOR" ||
              authRole === "CSA ADMIN ASSISTANT"
                ? null
                : isEdit
            }
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Contact Number"
            placeholder="Enter Contact Number"
            type="text"
            name="contactNumber"
            maxLength={10}
            required
            onChange={handleChange}
            value={values.contactNumber}
            onBlur={handleBlur}
            touched={touched.contactNumber}
            error={errors.contactNumber || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDatePicker
            label="Date of Birth"
            placeholder="Select Date of Birth"
            name="dateofBirth"
            setFieldValue={setFieldValue}
            value={values.dateofBirth}
            required
            onBlur={handleBlur}
            touched={touched.dateofBirth}
            error={errors.dateofBirth || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Race"
            placeholder="Select Race"
            required
            name="race"
            value={values.race}
            menuArr={masterData.raceList}
            onChange={(e) => {
              setFieldValue("race", e);
            }}
            onBlur={() => setFieldTouched("race", true)}
            touched={touched.race}
            error={errors.race || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Gender"
            placeholder="Select Gender"
            required
            name="gender"
            value={values.gender}
            menuArr={masterData.genderList}
            onChange={(e) => {
              setFieldValue("gender", e);
            }}
            onBlur={() => setFieldTouched("gender", true)}
            touched={touched.gender}
            error={errors.gender || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Differently Abled"
            placeholder="Select Differently Abled"
            required
            name="differentlyAbled"
            value={values.differentlyAbled}
            menuArr={masterData.differentlyAbled}
            onChange={(e) => {
              setFieldValue("differentlyAbled", e);
            }}
            defaultValue={values.differentlyAbled || "None"}
            onBlur={() => setFieldTouched("differentlyAbled", true)}
            touched={touched.differentlyAbled}
            error={errors.differentlyAbled || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({ step1: info?.step1, step2: values });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default HighSchoolRegisterForm2;
