/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaButton from "Common/CsaButton";
import styles from "./CsaLoginForm.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector, useDispatch } from "react-redux";
import {
  isLogin,
  setCSALevel,
  setCSARole,
  setLoginAsUid,
  setUserImgFid,
  setUserImgUrl,
} from "redux/slice/commonSlice";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    // .email("Invalid Email Address")
    .required("Enter Email Address"),
  password: Yup.string().required("Enter password"),
});

function CsaLoginForm() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.login,
    method: "post",
    isAuth: false,
  });

  const {
    callAPI: getSubmemberType,
    response: resSubMemberType,
    error: errSubMemberType,
    loading: loadSubMemberType,
  } = useAxios({
    url: serviceUrl.getSubMemberType,
    method: "get",
  });

  const {
    callAPI: getUserLogo,
    response: resUserLogo,
    error: errUserLogo,
    loading: loadUserLogo,
  } = useAxios({
    url: serviceUrl.getLogos,
    method: "get",
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const obj = {
        mail: values.username,
        pass: values.password,
      };

      callAPI(JSON.stringify(obj));
    },
  });

  const setHighestLevel = (arr, rolesArr) => {
    //
    if (arr?.includes("CSA") || arr?.includes("csa")) {
      getRoles("csa", rolesArr);
      return "csa";
    } else if (
      arr?.includes("MEMBER") ||
      arr?.includes("member") ||
      arr?.includes("Member")
    ) {
      getRoles("member", rolesArr);
      return "member";
    } else {
      getRoles("sub-member", rolesArr);
      return "sub-member";
    }
  };

  const getRoles = (e, roles) => {
    //
    let reqArr = [];
    let rolesByLevels =
      e === "Sub-Member" ||
      e === "SUB-MEMBER" ||
      e === "sub-member" ||
      e === "Sub-Members"
        ? roles?.filter(
            (item) =>
              item?.includes("sub_members") || item?.includes("Sub-Members") ||item?.includes("csa") || item?.includes("CSA")
          )
        : e === "MEMBER" || e === "member" || e === "Member" || e === "Members"
        ? roles?.filter(
            (item) =>
              item?.startsWith("member_") ||
              item?.startsWith("members_") ||
              item?.startsWith("Members") ||
              item?.startsWith("Member")
          )
        : roles?.filter(
            (item) => item?.includes("csa") || item?.includes("CSA")
          );
    rolesByLevels?.forEach((element) => {
      let role = element?.split("_")?.join(" ")?.trimStart()?.toUpperCase();
      reqArr.push(role);
    });
    localStorage.setItem("selRole", reqArr?.[0], reqArr);
    console.log(reqArr,e,roles,"ROOLE")
    dispatch(setCSARole(reqArr?.[0]));
  };

  useEffect(() => {
    if (response) {
      dispatch(isLogin(true));
      dispatch(setUserImgFid(response?.fid));
      dispatch(setUserImgUrl(response?.image_url));
      dispatch(setLoginAsUid(response?.current_user.uid));
      localStorage.setItem("csa_token", response?.access_token);
      localStorage.setItem("csa_csrf_token", response?.csrf_token);
      localStorage.setItem("csa_logout_token", response?.logout_token);
      localStorage.setItem(
        "csa_user_uid",
        JSON.stringify(response?.current_user.uid)
      );
      localStorage.setItem("csa_member_name", response?.member_name);
      localStorage.setItem("csa_member_id", response?.member_id);
      localStorage.setItem(
        "csa_current_user_f",
        JSON.stringify(response?.first_name?.[0]?.value)
      );
      localStorage.setItem(
        "csa_current_user_l",
        JSON.stringify(response?.last_name?.[0]?.value)
      );
      localStorage.setItem(
        "csa_province",
        JSON.stringify(
          response?.province?.[0]?.value ? response?.province?.[0]?.value : null
        )
      );
      localStorage.setItem("csa_fid", response?.fid);
      localStorage.setItem("csa_profile", response?.image_url);
      localStorage.setItem(
        "roles",
        JSON.stringify(response?.current_user?.roles)
      );

      const isMemberAdmin = [
        "members_admin_assistant",
        "members_administrator",
      ].some((item) => (response?.current_user?.roles).includes(item));
      const levelArr = response?.level?.map((x) => x.value);
      const topLevel =
        (levelArr?.includes("CSA") && "csa") ||
        (levelArr?.includes("MEMBER") && "member") ||
        "sub-member";
      console.log(topLevel, levelArr, "llll");

      let arr =
        isMemberAdmin && topLevel === "member"
          ? ["MEMBER", "SUB-MEMBER"]
          : response?.level?.map((item) => item?.value);
      //  let arr = ["Member"];
      //  response?.level?.map((item) => item?.value.split(",")) || [];
      //  let arr1 = JSON.stringify(arr);
      //  let arr2 = JSON.parse(arr1); //arr1;
      //  let unique = [...new Set(arr2)];
      //
      //   "arr1",
      //   arr1,
      //   arr2,
      //   setHighestLevel(unique, response?.current_user?.roles)
      //  );
      localStorage.setItem("user_level", JSON.stringify(arr));

      localStorage.setItem(
        "csa_level",
        setHighestLevel(arr, response?.current_user?.roles)
      );
      dispatch(
        setCSALevel(setHighestLevel(arr, response?.current_user?.roles))
      );

      //if level is csa then directly log user in
      if (response?.level[0]?.value === "CSA") {
        addToast("Login Success", { appearance: "success" }, () =>
          navigate("/home")
        );
        // if level is not csa then check for profile image in response . if not exist fetch the image based on member id
      } else if (response?.level[0]?.value !== "CSA" && !response?.image_url) {
        getUserLogo(`?type=member&member_id=${response?.member_id}`, true);
      }
      //if image available check the level .if it is member then login. if sub member fetch the sub member type then login
      else if (response?.level[0]?.value !== "CSA" && response?.image_url) {
        if (response?.level[0]?.value !== "SUB-MEMBER") {
          addToast("Login Success", { appearance: "success" }, () =>
            navigate("/home")
          );
        }
        if (response?.level[0]?.value === "SUB-MEMBER") {
          localStorage.setItem("sub_member_id", response?.id);
          localStorage.setItem(
            "sub_member_name",
            response?.firstname + " " + response?.lastname
          );
          getSubmemberType(response?.current_user?.uid, true);
        }
      }

      // if (response?.level[0]?.value === "SUB-MEMBER") {
      //   localStorage.setItem("sub_member_id", response?.id);
      //   localStorage.setItem(
      //     "sub_member_name",
      //     response?.firstname + " " + response?.lastname
      //   );
      //   getSubmemberType(response?.id, true);
      // }
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      addToast(error?.message, { appearance: "error" });
      dispatch(isLogin(false));
    }
  }, [error]);

  useEffect(() => {
    if (resUserLogo?.length) {
      if (response?.level[0]?.value !== "SUB-MEMBER") {
        dispatch(setUserImgUrl(resUserLogo?.[0]?.field_picture));
        dispatch(setUserImgFid(Number(resUserLogo?.[0]?.fid)));
        addToast("Login Success", { appearance: "success" }, () =>
          navigate("/home")
        );
      }
      if (response?.level[0]?.value === "SUB-MEMBER") {
        dispatch(setUserImgUrl(resUserLogo?.[0]?.field_picture));
        dispatch(setUserImgFid(Number(resUserLogo?.[0]?.fid)));
        localStorage.setItem("sub_member_id", response?.id);
        localStorage.setItem(
          "sub_member_name",
          response?.firstname + " " + response?.lastname
        );
        getSubmemberType(response?.current_user?.uid, true);
      }
    }
  }, [resUserLogo]);

  useEffect(() => {
    if (resSubMemberType) {
      localStorage.setItem("sub_member_type", resSubMemberType?.[0]?.type);
      localStorage.setItem("sub_member_type_list", resSubMemberType?.map((x) => x?.type));
      addToast("Login Success", { appearance: "success" }, () =>
        navigate("/home")
      );
    }
  }, [resSubMemberType]);

  useEffect(() => {
    if (errSubMemberType) {
      addToast(errSubMemberType.message || "Failed to get Sub Member Info", {
        appearance: "success",
      });
    }
  }, [errSubMemberType]);

  const {
    handleChange,
    errors,
    values,
    handleBlur,
    handleSubmit,
    touched,
  } = formik;
  const checkValidity = () => {
    const { username, password } = values;
    const isVAlid = username && password && Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };
  return (
    <div className={styles.CsaLoginFormWrapper}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <h1 className="text-center text-white">Login</h1>
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <CsaTextInput
            label="Email Address"
            placeholder="Enter Email Address"
            name="username"
            type="email"
            labelColor
            onChange={handleChange}
            value={values.username}
            required
            onBlur={handleBlur}
            touched={touched.username}
            error={errors.username || null}
          />
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <CsaTextInput
            label="Password"
            placeholder="Enter Password"
            type="password"
            name="password"
            labelColor
            onChange={handleChange}
            value={values.password}
            required
            onBlur={handleBlur}
            touched={touched.password}
            error={errors.password || null}
          />
        </Col>
        <div className="w-100 d-flex align-items-center justify-content-center mt-5 mb-2">
          <CsaButton
            label="Continue"
            textColor="#004f27"
            htmlType="submit"
            onClick={() => handleSubmit()}
            disabled={checkValidity()}
            loading={loading || loadSubMemberType || loadUserLogo}
          />
        </div>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <CsaButton
            label="Forgot Password/Username?"
            textColor="#FFCB03"
            type="link"
            onClick={() => navigate("/recovery")}
          />
        </div>
      </Row>
    </div>
  );
}

export default CsaLoginForm;
