import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaDropDown from "Common/CsaDropDown";
import CsaFileUpload from "Common/CsaFileUpload";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { useSelector } from "react-redux";
// const roles = localStorage.getItem("CSAPermissions")?.split(",");

const ScorerSchema = Yup.object().shape({
  gradeA: Yup.string().required("Select Grade1"),
  gradeB: Yup.string().required("Select Grade2"),
  gradeC: Yup.string().required("Select Grade3"),
  gradeD: Yup.string().required("Select GradeD"),
  premierLeague: Yup.string().required("Select Premier League"),
  status: Yup.string().required("Select Status"),
});

function ScorerRegisterForm2({ styles, setStep, setInfo, info, img, setImg }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const steps = ["Personal Information", "Qualifications"];
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const [uploadCert, setUploadCert] = useState([]);
  const userExisting = info?.userExisting;

  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editScorer : serviceUrl.setScorer,
    method: isEdit ? "put" : "post",
  });

  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };

      useEffect(() => {
    if (info?.files) {
      setUploadCert(info?.files)
    }
  }, [info]);

  function findCert(key) {
  const item = info?.files?.find(item => item.key === key);
  return item ? item.url.toString() : undefined;
}

console.log(uploadCert,info,"iii")

  const filesData = {
          certificateUploadgA:findCert("certificateUploadgA"),
          certificateUploadgB:findCert("certificateUploadgB") ,
          certificateUploadgC:findCert("certificateUploadgC"),
          certificateUploadgD:findCert("certificateUploadgD"),
  }

  const data =
    isEdit || userExisting
      ? {
          gradeA: info?.step2?.gradeA || info?.editData?.gradeA,
          dateObtainedgA:info?.step2?.dateObtainedgA || info?.editData?.dateObtainedgA,
          certificateUploadgA:findCert("certificateUploadgA") || info?.editData?.certificateUploadgA,
          gradeB:info?.step2?.gradeB || info?.editData?.gradeB,
          dateObtainedgB:info?.step2?.dateObtainedgB || info?.editData?.dateObtainedgB,
          certificateUploadgB:findCert("certificateUploadgB") || info?.editData?.certificateUploadgB,
          gradeC:info?.step2?.gradeC || info?.editData?.gradeC,
          dateObtainedgC:info?.step2?.dateObtainedgC || info?.editData?.dateObtainedgC,
          certificateUploadgC:findCert("certificateUploadgC") || info?.editData?.certificateUploadgC,
          gradeD:info?.step2?.gradeD || info?.editData?.gradeD,
          dateObtainedgD:info?.step2?.dateObtainedgD || info?.editData?.dateObtainedgD,
          certificateUploadgD:findCert("certificateUploadgD") || info?.editData?.certificateUploadgD,
          premierLeague: info?.editData?.premierLeague,
          status:
            info?.editData?.status === "Active" ? "Active" : "Disable" || "",
        }
      : {...info?.step2,...filesData} || {};
  const formik = useFormik({
    initialValues: {
      gradeA: data ? data?.gradeA || "No" : "No",
      dateObtainedgA: data ? data?.dateObtainedgA : "",
      certificateUploadgA: data ? data?.certificateUploadgA : "",
      gradeB: data ? data?.gradeB || "No" : "No",
      dateObtainedgB: data ? data?.dateObtainedgB : "",
      certificateUploadgB: data ? data?.certificateUploadgB : "",
      gradeC: data ? data?.gradeC || "No" : "No",
      dateObtainedgC: data ? data?.dateObtainedgC : "",
      certificateUploadgC: data ? data?.certificateUploadgC : "",
      gradeD: data ? data?.gradeD || "No" : "No",
      dateObtainedgD: data ? data?.dateObtainedgD : "",
      certificateUploadgD: data ? data?.certificateUploadgD : "",
      premierLeague: data ? data?.premierLeague || "No" : "No",
      status: data?.status || "Active",
    },
    validationSchema: ScorerSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.gradeA === "Yes" && !values.dateObtainedgA) {
        addToast("Please select date against gradeA", {
          appearance: "warning",
        });
      } else if (values.gradeB === "Yes" && !values.dateObtainedgB) {
        addToast("Please select date against gradeB", {
          appearance: "warning",
        });
      } else if (values.gradeC === "Yes" && !values.dateObtainedgC) {
        addToast("Please select date against gradeC", {
          appearance: "warning",
        });
      } else if (values.gradeD === "Yes" && !values.dateObtainedgD) {
        addToast("Please select date against gradeD", {
          appearance: "warning",
        });
      } else {
        setInfo({ step1: info?.step1, step2: values });
        const obj = {
          email_address: info?.step1?.emailAddress,
          nationality: info?.step1?.nationality,
          id_number: info?.step1?.iDNumber?.toString() || "",
          passport_number: info?.step1?.passportNumber?.toString() || "",
          contact_number: info?.step1?.contactNumber?.toString() || "",
          first_name: info?.step1?.firstName,
          last_name: info?.step1?.lastName,
          dob: info?.step1?.dateofBirth,
          race: info?.step1?.race,
          gender: info?.step1?.gender,
          differently_abled: info?.step1?.differentlyAbled,
          grade_a: values.gradeA === "Yes" ? true : false,
          grade_a_date: values.dateObtainedgA,
          grade_a_file: uploadCert?.find(
            (item) => item?.key === "certificateUploadgA"
          )?.value || info?.editData?.cfId1,
          grade_b: values.gradeB === "Yes" ? true : false,
          grade_b_date: values.dateObtainedgB,
          grade_b_file: uploadCert?.find(
            (item) => item?.key === "certificateUploadgB"
          )?.value || info?.editData?.cFId2,
          grade_c: values.gradeC === "Yes" ? true : false,
          grade_c_date: values.dateObtainedgC,
          grade_c_file: uploadCert?.find(
            (item) => item?.key === "certificateUploadgC"
          )?.value || info?.editData?.cFId3,
          grade_d: values.gradeD === "Yes" ? true : false,
          grade_d_date: values.dateObtainedgD,
          grade_d_file: uploadCert?.find(
            (item) => item?.key === "certificateUploadgD"
          )?.value || info?.editData?.dFId,
          premier_league: values.premierLeague === "Yes" ? true : false,
          status: values?.status === "Active" ? true : false || false,
          fid: info?.file,
          user_level_existing:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          user_level:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          roles:
            info?.userExisting === true
              ? ["scorers"]
              : isEdit
              ? null
              : ["scorers"],
          member_id: level === "CSA" ? "" : memberId,
          uid: uid,
          ...(authLevel === "sub-member" && {
            sub_member_id: sub_member_id,
            sub_member_name: sub_member_name,
          }),
        };
        callAPI(
          id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
        );
      }
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/scorer-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const checkValidity = () => {
    const { gradeA, gradeB, gradeC, gradeD, premierLeague, status } = values;
    const isVAlid =
      gradeA &&
      gradeB &&
      gradeC &&
      gradeD &&
      premierLeague &&
      status &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };
  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
     <CsaDropDown
      label="Grade A"
      placeholder="Select Grade1"
      name="gradeA"
      value={values.gradeA}
      menuArr={["Yes", "No"]}
      onChange={(e) => {
       setFieldValue("gradeA", e);
      }}
      required
      defaultValue={values.gradeA || "No"}
      onBlur={() => setFieldTouched("gradeA", true)}
      touched={touched.gradeA}
      error={errors.gradeA || null}
     />
    </Col>
    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
     <CsaDatePicker
      label="Date Obtained"
      placeholder="Select Date Obtained"
      name="dateObtainedgA"
      setFieldValue={setFieldValue}
      value={values.dateObtainedgA}
      onBlur={handleBlur}
      touched={touched.dateObtainedgA}
      error={errors.dateObtainedgA || null}
      required={values?.gradeA === "Yes" ? true : false}
      disabled={values?.gradeA === "No" ? true : false}
     />
    </Col>
    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
     <CsaFileUpload
      label="Certificate"
      type="file"
      name="certificateUploadgA"
      setFile={setUploadCert}
      files={uploadCert}
      editFile={info?.editData?.certificateUploadgA}
      editFileId={info?.editData?.cFId1}
      disabled={values?.gradeA === "No" ? true : false}
     />
    </Col> */}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Grade B"
            placeholder="Select GradeB"
            name="gradeB"
            value={values.gradeB}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("gradeB", e);
            }}
            defaultValue={values.gradeB || "No"}
            onBlur={() => setFieldTouched("gradeB", true)}
            touched={touched.gradeB}
            error={errors.gradeB || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedgB"
            setFieldValue={setFieldValue}
            value={values.dateObtainedgB}
            onBlur={handleBlur}
            touched={touched.dateObtainedgB}
            error={errors.dateObtainedgB || null}
            required={values?.gradeB === "Yes" ? true : false}
            disabled={values?.gradeB === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadgB"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadgB}
            editFileId={data?.cFId2}
            disabled={values?.gradeB === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Grade C"
            placeholder="Select GradeC"
            name="gradeC"
            value={values.gradeC}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("gradeC", e);
            }}
            required
            defaultValue={values.gradeC || "No"}
            onBlur={() => setFieldTouched("gradeC", true)}
            touched={touched.gradeC}
            error={errors.gradeC || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedgC"
            setFieldValue={setFieldValue}
            value={values.dateObtainedgC}
            onBlur={handleBlur}
            touched={touched.dateObtainedgC}
            error={errors.dateObtainedgC || null}
            required={values?.gradeC === "Yes" ? true : false}
            disabled={values?.gradeC === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadgC"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadgC}
            editFileId={data?.cFId3}
            disabled={values?.gradeC === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Grade D"
            placeholder="Select GradeD"
            name="gradeD"
            value={values.gradeD}
            required
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("gradeD", e);
            }}
            defaultValue={values.gradeD || "No"}
            onBlur={() => setFieldTouched("gradeD", true)}
            touched={touched.gradeD}
            error={errors.gradeD || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedgD"
            setFieldValue={setFieldValue}
            value={values.dateObtainedgD}
            onBlur={handleBlur}
            touched={touched.dateObtainedgD}
            error={errors.dateObtainedgD || null}
            required={values?.gradeD === "Yes" ? true : false}
            disabled={values?.gradeD === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadgD"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadgD}
            editFileId={data?.cFId4}
            disabled={values?.gradeD === "No" ? true : false}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Premier League"
            placeholder="Select Premier League"
            name="premierLeague"
            menuArr={["Yes", "No"]}
            value={values.premierLeague}
            onChange={(e) => {
              setFieldValue("premierLeague", e);
            }}
            defaultValue={values.premierLeague || "No"}
            required
            onBlur={() => setFieldTouched("premierLeague", true)}
            touched={touched.premierLeague}
            error={errors.premierLeague || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({ step1: info?.step1, step2: values,file:info?.file ,files:uploadCert});
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default ScorerRegisterForm2;
