import { Card, Col, Row, Avatar, Image, Tag } from "antd";
import React from "react";

export default function FieldView(props) {
  const { viewData } = props;

  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: "#f1f1f1",
        justifyContent: "center",
        borderRadius: 15,
        borderWidth: 0.5,
        borderColor: "#f3f3f3",
      }}
    >
      <Row>
        {viewData && (
          <>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Field Name</h6>
              <p>{viewData?.field_name}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Pitch Type</h6>
              <p>{viewData?.field_pitch_type || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">No of Pitches</h6>
              <p>{viewData?.field_no_of_pitches || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Square Covers Available</h6>
              <p>{viewData?.field_square_covers_available || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Spectators Capacity</h6>
              <p>{viewData?.field_spectators_capacity || "N/A"}</p>
            </Col>
                   <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Address</h6>
              <p>{viewData?.field_address || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Adequate Boundary Rope </h6>
              <p>{viewData?.field_adequate_boundary_rope || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">
                Floodlights Adequate for Day/Night Practice
              </h6>
              <p>{viewData?.field_floodlights || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Bomag Roller</h6>
              <p>{viewData?.field_bomag_roller || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Status</h6>
              <p>
                {viewData?.field_status === "Active"
                  ? "Active"
                  : "Disable" || "N/A"}
              </p>
            </Col>
            {viewData?.field_field_claims && (
              <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
                <h6 className="mb-1">Linked with :</h6>
                <p>{viewData?.field_field_claims || ""}</p>
              </Col>
            )}
          </>
        )}
      </Row>
    </Card>
  );
}
