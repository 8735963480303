import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";

//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaImageUpload from "Common/CsaImageUpload";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import axios from "axios";
import TeamView from "pages/Teams/TeamView";
import useAxios from "utils/axios";
import { imgUrlToFile } from "utils/helpers";
import { useToasts } from "react-toast-notifications";
const TeamsSchema = Yup.object().shape({
  teamName: Yup.string().required("Enter Team Name"),
  ageGroup: Yup.string().required("Select age group"),
  level: Yup.string().required("Select Level"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
  status: Yup.string().required("Select Status"),
});
function TeamRegisterForm({ styles, setStep, setInfo, info, img, setImg }) {
  const authLevel = localStorage.getItem("csa_level");
  console.log(authLevel, "authLevel");
  const location = useLocation();
  const { isView = false, id, isEdit = false } = location?.state || {};
  const steps = ["Team Info", "Management Team", "Squad"];

  const [playerData, setPlayerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const { addToast } = useToasts();
  const sub_member_id = localStorage.getItem("sub_member_id");
  const member_id = localStorage.getItem("csa_member_id");
  const csa_user_id = localStorage.getItem("csa_user_uid");

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.teamDetails + `${id}`,
    method: "get",
  });

  const {
    callAPI: fetchValidate,
    response: resValidate,
    error: errValidate,
    loading: loadValidate,
  } = useAxios({
    url: serviceUrl.validateTeam,
    method: "get",
  });

  useEffect(() => {
    if (!hasNextPage) return;
    const fetchData = async () => {
      try {
        const res = await axios.get(
          serviceUrl.getPlayersList + id + "?page=" + currentPage
        );

        const data = res?.data || [];
        setPlayerData([...playerData, ...data?.rows]);
        const curPg = data?.pager?.current_page;
        const total = data?.pager?.total_pages;

        if (curPg + 1 >= total) {
          setHasNextPage(false);

          return;
        } else {
          setHasNextPage(true);
          setCurrentPage(currentPage + 1);
        }
        setCurrentPage(currentPage + 1);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [currentPage, hasNextPage]);

  const data = info?.step1
    ? info?.step1
    : {
        teamName: response?.[0]?.team_name?.replace(/&#039;/g, "'") || "",
        ageGroup: response?.[0]?.field_age_group || "",
        level: response?.[0]?.field_team_level || "",
        gender: response?.[0]?.field_gender || "",
        team_a: response?.[0]?.team_a,
        differentlyAbled: response?.[0]?.field_differently_abled || "",
        status:
          response?.[0]?.field_status === "Yes" ? "Active" :response?.[0]?.field_status ===  "No" ? "Disable" : "Active",
      } || {};
  useEffect(() => {
    if(info?.file) {
  setFileId(info?.file)
}
    if (response && response?.[0]?.profile && img?.length === 0 && isEdit) {
      imgUrlToFile(response?.[0]?.profile, response?.[0]?.fid, setImg);
      setFileId(response?.[0]?.fid);
    }
  }, [response]);
 
  const formik = useFormik({
    initialValues: {
      teamName: data ? data?.teamName?.replace(/&#039;/g, "'") : "",
      ageGroup: data ? data?.ageGroup : "",
      level: data ? data?.level : "",
      gender: data ? data?.gender : "",
      differentlyAbled: data ? data?.differentlyAbled || "None" : "None",
      status: data ? data?.status : "",
      a_team: data?.a_team ? true : data ? data?.team_a === 1 && true : false,
    },
    validationSchema: TeamsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: values,
        step2: info?.step2,
        step3: info?.step3,
        file: fileId,
        editData: response?.[0] || null,
        players: playerData,
      });
      setStep(2);
    },
  });

  useEffect(() => {
    if (
      response &&
      response?.[0]?.field_profile &&
      img?.length === 0 &&
      isEdit
    ) {
      imgUrlToFile(response?.[0]?.field_profile, response?.[0]?.fid, setImg);
      setFileId(response?.[0]?.fid);
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  useEffect(() => {
    if (isEdit || isView) {
      callAPI();
    }
  }, [isEdit, isView]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const checkValidity = () => {
    const {
      teamName,
      ageGroup,
      level,
      gender,
      differentlyAbled,
      status,
    } = values;
    const isVAlid =
      teamName &&
      ageGroup &&
      level &&
      gender &&
      differentlyAbled &&
      status &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);

  useEffect(() => {
    if (values?.gender && values?.ageGroup) {
      if (authLevel === "member") {
        fetchValidate(
          `${authLevel.toUpperCase()}/${member_id}/${values?.gender}/${
            values?.ageGroup
          }`,
          true
        );
      } else if (authLevel === "sub-member") {
        fetchValidate(
          `${authLevel.toUpperCase()}/${sub_member_id}/${values?.gender}/${
            values?.ageGroup
          }`,
          true
        );
      } else if (authLevel === "csa") {
        fetchValidate(
          `${authLevel.toUpperCase()}/${Number(448)}/${values?.gender}/${
            values?.ageGroup
          }`,
          true
        );
      }
    }
  }, [values?.ageGroup, values?.gender]);

  console.log(data,values, "image");

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    // const file = data1?.file;
    const reader = new FileReader();
    reader.onload = function (e) {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = file.name;
      const headers = {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("csa_token"),
        "Content-Disposition": `file;filename="${fileName}"`,
      };
      axios
        .post(serviceUrl.imgUpload, blob, {
          headers: headers,
        })
        .then((res) => {
          setFileId(res?.data?.fid?.[0]?.value);
          onSuccess(file);
        })
        .catch((err) => {
          onError({ event: err });
        });
    };
    reader.readAsArrayBuffer(file);
    return true;
  };

  const props = {
    name: "file",
    fileList: img,
    customRequest: uploadImage,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImg(info?.fileList);
    },
    onRemove() {
      setFileId(null);
    },
  };

  return (
    <>
      {loading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : isView ? (
        <TeamView viewData={response?.[0]} />
      ) : (
        <>
          <CsaStepper steps={steps} activeKey={0} />

          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className={styles.imageUpload}
            >
              <CsaImageUpload {...props} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Team Name"
                placeholder="Enter Team Name"
                type="text"
                name="teamName"
                onChange={handleChange}
                value={values.teamName}
                required
                onBlur={handleBlur}
                touched={touched.teamName}
                error={errors.teamName || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Level"
                placeholder="Select Level"
                required
                name="level"
                value={values.level}
                menuArr={
                  authLevel === "member"
                    ? masterData.optionsTeamLevelMember
                    : authLevel === "csa"
                    ? masterData.optionsTeamLevelCsa
                    : masterData.optionsTeamLevelSubMember
                }
                onChange={(e) => {
                  setFieldValue("level", e);
                }}
                onBlur={() => setFieldTouched("level", true)}
                touched={touched.level}
                error={errors.level || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Age Group"
                placeholder="Select Age Group"
                required
                name="ageGroup"
                value={values.ageGroup}
                menuArr={masterData.ageGroupArr}
                onChange={(e) => {
                  setFieldValue("ageGroup", e);
                }}
                onBlur={() => setFieldTouched("ageGroup", true)}
                touched={touched.ageGroup}
                error={errors.ageGroup || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Gender"
                placeholder="Select Gender"
                required
                name="gender"
                value={values.gender}
                menuArr={masterData.genderListTeam}
                onChange={(e) => {
                  setFieldValue("gender", e);
                }}
                onBlur={() => setFieldTouched("gender", true)}
                touched={touched.gender}
                error={errors.gender || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Differently Abled"
                placeholder="Select Differently Abled"
                required
                name="differentlyAbled"
                value={values.differentlyAbled}
                menuArr={masterData.differentlyAbled}
                onChange={(e) => {
                  setFieldValue("differentlyAbled", e);
                }}
                defaultValue={values.differentlyAbled || "None"}
                onBlur={() => setFieldTouched("differentlyAbled", true)}
                touched={touched.differentlyAbled}
                error={errors.differentlyAbled || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Status"
                placeholder="Select Status"
                required
                name="status"
                value={values.status}
                menuArr={masterData.statusList}
                onChange={(e) => {
                  setFieldValue("status", e);
                }}
                onBlur={() => setFieldTouched("status", true)}
                touched={touched.status}
                error={errors.status || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {loadValidate ? (
                <Spin />
              ) : (
                <>
                  <p
                    style={{
                      gap: 5,
                      color: "#000000",
                      fontSize: "15px",
                      // backgroundColor: "green",
                    }}
                  >
                    1st / A Team ?:
                  </p>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Checkbox
                      disabled={!data?.team_a && resValidate?.exists}
                      name="a_team"
                      defaultChecked={values.a_team}
                      value={values.a_team}
                      onChange={(e) =>
                        setFieldValue("a_team", e.target.checked)
                      }
                    />
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "15px",
                      }}
                    >
                      Note: Only one 1st / A Team per Age Group + Gender allowed
                    </p>
                  </div>{" "}
                </>
              )}
            </Col>
            <div className="mt-4 d-flex w-100 justify-content-end">
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Continue"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  disabled={checkValidity()}
                />
              </Space>
            </div>
          </Row>
        </>
      )}
    </>
  );
}

export default TeamRegisterForm;
