import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Row, Col, Pagination } from "antd";
import { useFormik } from "formik";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import { findHightestLevel, fixSpecialChar } from "utils/helpers";
import { setCMSData } from "redux/slice/commonSlice";

function CMSScores({ styles, setStep, setInfo, info, setLocal }) {
  const cms_data = useSelector((state) => state.auth.cms_data);

  const { addToast } = useToasts();
  const [emptyFields, setIsEmptyFields] = useState(false);
  const tabData = cms_data?.tab2?.step1?.membersNameP || [];
  const navigate = useNavigate();
  const steps = ["Scorers", "Umpires"];
  const columns1 = [
    {
      title: "First Name",
      dataIndex: "fName",
      key: "fName",
    },
    {
      title: "Last Name",
      dataIndex: "lName",
      key: "lName",
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "Premier League",
      dataIndex: "premierLeague",
      key: "premierLeague",
    },
    {
      title: "Action",
      key: "action",
      render: (item) =>
        filteredTableData2.some((i) => i.key === item?.key) ? (
          <CheckOutlined style={{ cursor: "pointer", color: "#007237" }} />
        ) : (
          <PlusOutlined
            style={{ cursor: "pointer", color: "#007237" }}
            onClick={() => handleAdd(item)}
          />
        ),
      // align: "center",
    },
  ];

  const reqArr = cms_data?.memberRes?.filter((item) =>
    cms_data?.partMembers?.includes(item?.id)
  );

  const [tableData2, settableData2] = useState(
    //  cms_data?.tab3Step1?.tableData2 || cms_data?.tab3Step1 || []
    cms_data?.tab3Step1?.tableData2 || []
  );
  const handleAdd = (arrField) => {
    const isThere = tableData2?.find((item) => item?.key === arrField?.key);
    if (!isThere) {
      settableData2([...tableData2, arrField]);
    } else {
      addToast("Same Scorer added Already", {
        appearance: "warning",
      });
    }

    //  else {
    //   addToast("Same scorer added Already", {
    //     appearance: "warning",
    //   });
    // }
  };
  const handleRemove = (fieldArr) => {
    const arr = tableData2?.filter((item) => item?.key !== fieldArr);
    settableData2(arr);
  };
  const columns = [
    {
      title: "First Name",
      dataIndex: "fName",
      key: "fName",
    },
    {
      title: "Last Name",
      dataIndex: "lName",
      key: "lName",
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "Premier League",
      dataIndex: "premierLeague",
      key: "premierLeague",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <DeleteOutlined
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleRemove(item?.id)}
        />
      ),
      // align: "center",
    },
  ];

  const data = cms_data?.tab3step1 || {};
  const {
    callAPI: SearchAPI,
    response: searchResponse,
    error: searchErr,
    loading: searchLoad,
  } = useAxios({
    url: serviceUrl.getCMSScorerList,
    method: "get",
    query: true,
  });

  useEffect(() => {
    SearchAPI();
  }, []);

  const formik = useFormik({
    initialValues: {
      membersName: data ? data?.membersName : "",
      grade: data ? data?.grade : "",
      firstName: data ? fixSpecialChar(data?.firstName) : "",
      lastName: data ? fixSpecialChar(data?.lastName) : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSearch(values),
  });
const handleSearch = (val) => {
  const { membersName, firstName, lastName } = val;

  if (!membersName && !firstName && !lastName) {
    SearchAPI(); 
  } else if (membersName && !firstName && !lastName) {
    SearchAPI(`?keyword=&uid=&member_id=${membersName}`, true); 
  } else if (membersName && firstName && !lastName) {
    SearchAPI(`?keyword=&first_name=${firstName}&uid=&member_id=${membersName}`, true); 
  } else if (membersName && firstName && lastName) {
    SearchAPI(
      `?keyword=&first_name=${firstName}&last_name=${lastName}&uid=&member_id=${membersName}`,
      true
    ); 
  } else if (!membersName && firstName && lastName) {
    SearchAPI(`?keyword=${firstName + lastName}&uid=&member_id=All`, true);
  } else if (!membersName && firstName && !lastName) {
    SearchAPI(`?keyword=${firstName}&uid=&member_id=All`, true); 
  } else if (!membersName && !firstName && lastName) {
    SearchAPI(`?keyword=${lastName}&uid=&member_id=All`, true); 
  }
};

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const tableDataRes = searchResponse?.rows?.map((item) => ({
    ...item,
    key: item?.id,
    field_first_name: fixSpecialChar(item?.field_first_name),
    field_last_name: fixSpecialChar(item?.field_last_name),
    firstname: fixSpecialChar(item?.field_first_name),
    lastname: fixSpecialChar(item?.field_last_name),
    grade: findHightestLevel(
      item.grade_a,
      item.grade_b,
      item.grade_c,
      item.grade_d,
      "fromScorer"
    ),
    premierLeague: item?.field_premier_league,
    member:
      tabData?.find((x) => x?.id == item?.member_id)?.name || item?.member_name,
  }));
  const memberArr = cms_data?.tab2Step1?.membersNameP;

  const tableData = values?.grade
    ? tableDataRes?.filter((x) => x?.grade == values?.grade)
    : tableDataRes;
  const dispatch = useDispatch();
  const onContinueClick = async () => {
    setInfo({
      scorer: tableData2,
    });
    await dispatch(
      setCMSData({
        ...cms_data,
        tab1Step1: cms_data?.tab1Step1,
        tab1Step2: cms_data?.tab1Step2,
        tab1: cms_data?.tab1,
        tab2Step1: cms_data?.tab2Step1,
        tab2Step2: cms_data?.tab2Step2,
        tab2Step3: cms_data?.tab2Step3,
        tab2: cms_data?.tab2,
        tab3Step1: tableData2,
        tab3Step2: cms_data?.tab3Step2,
        tab3: cms_data?.tab3,
      })
    );
    setStep(2);
  };
const {membersName,firstName} = values
  const onChange = (page) => {
    if(firstName) {
    SearchAPI(`?page=${page - 1}&per_page=10&member_id=${membersName || "All"}?keyword=&first_name=${firstName}`, true);
    } else {
    SearchAPI(`?page=${page - 1}&per_page=10&member_id=${membersName || "All"}`, true);
    }
  };

  const filteredTableData = tableData?.map((x) => ({
    fName: x?.firstname?.replace(/&#039;/g, "'"),
    lName: x?.lastname?.replace(/&#039;/g, "'"),
    ...x,
  }));

  const filteredTableData2 = tableData2?.map((x) => ({
    fName: x?.firstname?.replace(/&#039;/g, "'"),
    lName: x?.lastname?.replace(/&#039;/g, "'"),
    ...x,
  }));

  return (
    <div className="mt-3 pt-3 px-3">
      <CsaStepper steps={steps} activeKey={0} />
      {/* {fetching ? (
    <Spin />
   ) : ( */}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3 "
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Member Name"
            placeholder="Select Member"
            name="membersName"
            value={values.membersName}
            menuArr={reqArr?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            optionType={true}
            onChange={(e) => {
              setFieldValue("membersName", e);
            }}
            onBlur={() => setFieldTouched("membersName", true)}
            touched={touched.membersName}
            error={errors.membersName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Grade"
            placeholder="Select Grade"
            name="grade"
            value={values.grade}
            optionType
            menuArr={masterData.gradeList}
            onChange={(e) => {
              setFieldValue("grade", e);
            }}
            defaultValue={values.grade}
            onBlur={handleBlur}
            touched={touched.grade}
            error={errors.grade || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="First Name"
            placeholder="Enter First Name"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            touched={touched.firstName}
            error={errors.firstName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Last Name"
            placeholder="Enter Last Name"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            touched={touched.lastName}
            error={errors.lastName || null}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-end"
        >
          <CsaButton
            label="Search"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            loading={searchLoad}
            //   disabled={checkValidity()}
          />
        </Col>
      </Row>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Search Results:</p>
        <Table
          className="center-aligned"
          loading={searchLoad}
          columns={columns1}
          dataSource={filteredTableData}
          pagination={false}
          scroll={{
            x: 650,
          }}
          rowClassName={(rec) =>
            filteredTableData2.some((item) => item.key === rec?.key) &&
            "disabled-row"
          }
        />
      </div>
      <div className=" d-flex w-100 justify-content-end mt-5">
        <Pagination
          onChange={onChange}
          current={searchResponse?.pager?.current_page + 1}
          total={searchResponse?.pager?.total_items}
          size={"small"}
          showSizeChanger={false}
        />
      </div>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Selected Scorers:</p>
        <Table
          className="center-aligned"
          //   loading={loading}
          columns={columns}
          dataSource={filteredTableData2}
          pagination={false}
          scroll={{
            x: 650,
          }}
        />
        {/* <div className=" d-flex w-100 justify-content-end mt-5">
     <Pagination
       onChange={onChange}
       //    total={response?.pager?.total_items}
       size={"small"}
      />
    </div> */}
      </div>
      <div className="mt-4 d-flex w-100 justify-content-end">
        <CsaButton
          label="Continue"
          textColor="#FFF"
          onClick={() => onContinueClick()}
          btnColor="#007237"
          disabled={tableData2?.length === 0}
        />
      </div>

      {/* )} */}
    </div>
  );
}

export default CMSScores;
