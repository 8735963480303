import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaDropDown from "Common/CsaDropDown";
import CsaFileUpload from "Common/CsaFileUpload";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import axios from "axios";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { useSelector } from "react-redux";

// const roles = localStorage.getItem("CSAPermissions")?.split(",");

const UmpireSchema = Yup.object().shape({
  level1: Yup.string().required("Select Level1"),
  level2: Yup.string().required("Select Level2"),
  level3: Yup.string().required("Select Level3"),
  national: Yup.string().required("Select national"),
  inceptionDate: Yup.string().required("Select Inception Date"),
  highestLevelOfficiated: Yup.string().required(
    "Select highest Level Officiated"
  ),
  status: Yup.string().required("Select Status"),
});

function UmpireRegisterForm2({ styles, setStep, setInfo, info, img, setImg }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const steps = ["Personal Information", "Experience"];
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const [uploadCert, setUploadCert] = useState([]);
  const userExisting = info?.userExisting;
  // const user_level_existing =
  //   (authLevel === "sub-member" && sub_member_id) ||
  //   (authLevel === "member" && memberId) ||
  //   (authLevel === "csa" && uid);

  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editUmpire : serviceUrl.setUmpire,
    method: isEdit ? "put" : "post",
  });

  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };

      useEffect(() => {
    if (info?.files) {
      setUploadCert(info?.files)
    }
  }, [info]);

  function findCert(key) {
  const item = info?.files?.find(item => item.key === key);
  return item ? item.url.toString() : undefined;
}

  const filesData = {
          certificateUploadl1:findCert("certificateUploadl1"),
          certificateUploadl2:findCert("certificateUploadl2") ,
          certificateUploadl3:findCert("certificateUploadl3"),
           certificateUploadNational:findCert("certificateUploadNational")
  }

  const data =
    isEdit || userExisting
      ? {
          level1: info?.step2?.level1 || info?.editData?.level1,
          dateObtainedl1: info?.step2?.dateObtainedl1 || info?.editData?.dateObtainedl1,
          certificateUploadl1:findCert("certificateUploadl1") ||info?.editData?.certificateUploadl1,
          level2:info?.step2?.level2 || info?.editData?.level2,
          dateObtainedl2:info?.step2?.dateObtainedl2 || info?.editData?.dateObtainedl2,
          certificateUploadl2:findCert("certificateUploadl2") || info?.editData?.certificateUploadl2,
          level3:info?.step2?.level3 || info?.editData?.level3,
          dateObtainedl3:info?.step2?.dateObtainedl3 || info?.editData?.dateObtainedl3,
          certificateUploadl3:findCert("certificateUploadl3") || info?.editData?.certificateUploadl3,
          national:info?.step2?.national || info?.editData?.national,
          dateObtainedNational:info?.step2?.dateObtainedNational || info?.editData?.dateObtainedNational,
          certificateUploadNational:findCert("certificateUploadNational") || info?.editData?.certificateUploadNational,
          inceptionDate: info?.editData?.inceptionDate,
          highestLevelOfficiated: info?.editData?.highestLevelOfficiated,
          premierLeague: info?.editData?.premierLeague,
          iCCPanel: info?.editData?.iCCPanel,
          status: info?.editData?.status,
        }
      : {...info?.step2,...filesData} || {};

  const formik = useFormik({
    initialValues: {
      level1: data ? data?.level1 || "No" : "No",
      dateObtainedl1: data ? data?.dateObtainedl1 : "",
      certificateUploadl1: data ? data?.certificateUploadl1 : "",
      level2: data ? data?.level2 || "No" : "No",
      dateObtainedl2: data ? data?.dateObtainedl2 : "",
      certificateUploadl2: data ? data?.certificateUploadl2 : "",
      level3: data ? data?.level3 || "No" : "No",
      dateObtainedl3: data ? data?.dateObtainedl3 : "",
      certificateUploadl3: data ? data?.certificateUploadl3 : "",
      national: data ? data?.national || "No" : "No",
      dateObtainedNational: data ? data?.dateObtainedNational : "",
      certificateUploadNational: data ? data?.certificateUploadNational : "",
      inceptionDate: data ? data?.inceptionDate : "",
      highestLevelOfficiated: data ? data?.highestLevelOfficiated : "",
      premierLeague: data ? data?.premierLeague || "No" : "No",
      iCCPanel: data ? data?.iCCPanel : "",
      status: data?.status || "Active",
    },
    validationSchema: UmpireSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.level1 === "Yes" && !values.dateObtainedl1) {
        addToast("Please select date against level1", {
          appearance: "warning",
        });
      } else if (values.level2 === "Yes" && !values.dateObtainedl2) {
        addToast("Please select date against level2", {
          appearance: "warning",
        });
      } else if (values.level3 === "Yes" && !values.dateObtainedl3) {
        addToast("Please select date against level3", {
          appearance: "warning",
        });
      } else if (values.national === "Yes" && !values.dateObtainedNational) {
        addToast("Please select date against national", {
          appearance: "warning",
        });
      } else {

        const levelFiles = {
          level1: values.level1 === "Yes" ? true : false,
          level1_date: values.dateObtainedl1,
          level1_file:
            uploadCert?.find((item) => item?.key === "certificateUploadl1")
              ?.value || info?.editData?.cFId1,
          level2: values.level2 === "Yes" ? true : false,
          level2_date: values.dateObtainedl2,
          level2_file:
            uploadCert?.find((item) => item?.key === "certificateUploadl2")
              ?.value  || info?.editData?.cFId2,
          level3: values.level3 === "Yes" ? true : false,
          level3_date: values.dateObtainedl3,
          level3_file:
            uploadCert?.find((item) => item?.key === "certificateUploadl3")
              ?.value   || info?.editData?.cFId3,
          national: values.national === "Yes" ? true : false,
          national_date: values.dateObtainedNational,
          national_file:
            uploadCert?.find(
              (item) => item?.key === "certificateUploadNational"
            )?.value  || info?.editData?.nationalFId,
        }

        setInfo({ step1: info?.step1, step2: values,files:uploadCert });
        setStep(2);
        const obj = {
          email_address: info?.step1?.emailAddress,
          nationality: info?.step1?.nationality,
          id_number: info?.step1?.iDNumber?.toString() || "",
          passport_number: info?.step1?.passportNumber?.toString() || "",
          contact_number: info?.step1?.contactNumber?.toString() || "",
          first_name: info?.step1?.firstName,
          last_name: info?.step1?.lastName,
          dob: info?.step1?.dateofBirth,
          race: info?.step1?.race,
          gender: info?.step1?.gender,
          differently_abled: info?.step1?.differentlyAbled,
          ...levelFiles,
          inception_date: values.inceptionDate,
          highest_level: values.highestLevelOfficiated,
          premier_league: values.premierLeague === "Yes" ? true : false,
          icc_panel: values.iCCPanel === "Yes" ? true : false,
          status: values?.status === "Active" ? true : false || false,
          fid: info?.file,
          user_level_existing:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          user_level:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          roles:
            info?.userExisting === true
              ? ["umpire"]
              : isEdit
              ? null
              : ["umpire"],
          member_id: level === "CSA" ? "" : memberId,
          uid: uid,
        };

        callAPI(
          id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
        );
      }
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/umpire-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const checkValidity = () => {
    const {
      level1,
      level2,
      level3,
      national,
      inceptionDate,
      highestLevelOfficiated,
      status,
    } = values;
    const isVAlid =
      level1 &&
      level2 &&
      level3 &&
      national &&
      inceptionDate &&
      highestLevelOfficiated &&
      status &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  console.log(info,data,"info")

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 1"
            placeholder="Select Level1"
            name="level1"
            value={values.level1}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level1", e);
            }}
            required
            defaultValue={values.level1 || "No"}
            onBlur={() => setFieldTouched("level1", true)}
            touched={touched.level1}
            error={errors.level1 || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl1"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl1}
            onBlur={handleBlur}
            touched={touched.dateObtainedl1}
            error={errors.dateObtainedl1 || null}
            required={values?.level1 === "Yes" ? true : false}
            disabled={values?.level1 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadl1"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl1}
            disabled={values?.level1 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 2"
            placeholder="Select Level2"
            name="level2"
            value={values.level2}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level2", e);
            }}
            required
            defaultValue={values.level2 || "No"}
            onBlur={() => setFieldTouched("level2", true)}
            touched={touched.level2}
            error={errors.level2 || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl2"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl2}
            onBlur={handleBlur}
            touched={touched.dateObtainedl2}
            error={errors.dateObtainedl2 || null}
            required={values?.level2 === "Yes" ? true : false}
            disabled={values?.level2 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadl2"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl2}
            disabled={values?.level2 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 3"
            placeholder="Select Level3"
            name="level3"
            value={values.level3}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level3", e);
            }}
            required
            defaultValue={values.level3 || "No"}
            onBlur={() => setFieldTouched("level3", true)}
            touched={touched.level3}
            error={errors.level3 || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl3"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl3}
            onBlur={handleBlur}
            touched={touched.dateObtainedl3}
            error={errors.dateObtainedl3 || null}
            required={values?.level3 === "Yes" ? true : false}
            disabled={values?.level3 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadl3"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl3}
            disabled={values?.level3 === "No" ? true : false}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="National"
            placeholder="Select national"
            name="national"
            required
            menuArr={["Yes", "No"]}
            value={values.national}
            onChange={(e) => {
              setFieldValue("national", e);
            }}
            defaultValue={values.national || "No"}
            onBlur={() => setFieldTouched("national", true)}
            touched={touched.national}
            error={errors.national || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedNational"
            setFieldValue={setFieldValue}
            value={values.dateObtainedNational}
            onBlur={handleBlur}
            touched={touched.dateObtainedNational}
            error={errors.dateObtainedNational || null}
            required={values?.national === "Yes" ? true : false}
            disabled={values?.national === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate"
            type="file"
            name="certificateUploadNational"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadNational}
            disabled={values?.national === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Inception Date"
            placeholder="Select Inception Date"
            required
            name="inceptionDate"
            setFieldValue={setFieldValue}
            value={values.inceptionDate}
            onBlur={() => setFieldTouched("inceptionDate", true)}
            touched={touched.inceptionDate}
            error={errors.inceptionDate || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Highest Level Officiated"
            placeholder="Select Highest Level Officiated"
            required
            name="highestLevelOfficiated"
            menuArr={masterData.highestLevelOfficiatedList}
            value={values.highestLevelOfficiated}
            onChange={(e) => {
              setFieldValue("highestLevelOfficiated", e);
            }}
            onBlur={() => setFieldTouched("highestLevelOfficiated", true)}
            touched={touched.highestLevelOfficiated}
            error={errors.highestLevelOfficiated || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Premier League"
            placeholder="Select Premier League"
            name="premierLeague"
            menuArr={["Yes", "No"]}
            value={values.premierLeague}
            onChange={(e) => {
              setFieldValue("premierLeague", e);
            }}
            defaultValue={values.premierLeague || "No"}
            onBlur={handleBlur}
            touched={touched.premierLeague}
            error={errors.premierLeague || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="ICC Panel"
            placeholder="Select ICC Panel"
            name="iCCPanel"
            menuArr={["Yes", "No"]}
            value={values.iCCPanel}
            onChange={(e) => {
              setFieldValue("iCCPanel", e);
            }}
            defaultValue={values.iCCPanel || "No"}
            onBlur={handleBlur}
            touched={touched.iCCPanel}
            error={errors.iCCPanel || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({ step1: info?.step1, step2: values,file:info?.file,files:uploadCert });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default UmpireRegisterForm2;
